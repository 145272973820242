import React from 'react';

function App() {
    const scale = Math.min(window.screen.width / 360, 1);
    return (
        <div
            style={{
                width: '100vw',
                height: '100vh',
                backgroundSize: 'cover',
                backgroundImage: 'url("/background.jpg")',
                WebkitTransform: `scale(${scale})`,
                MsTransform: `scale(${scale})`,
                transform: `scale(${scale})`,
            }}
        />
    );
}

export default App;
